<template>
  <div>
    <Table :columns="columns1" :data="data1" border></Table>
    <Row>
      <Col class="test" :xs="2" :sm="4" :md="6" :lg="8">
      <div class="box">Col11</div>
      </Col>
      <Col class="test" :xs="20" :sm="16" :md="12" :lg="8">
      <div class="box">Col22</div>
      </Col>
      <Col class="test" :xs="2" :sm="4" :md="6" :lg="8">
      <div class="box">Col33</div>
      </Col>
    </Row>
  </div>
</template>
<script>
export default {
  data() {
    return {
      columns1: [
        {
          title: "分类",
          align: "center",
          key: "type",
          width: 150,
        },
        {
          title: "标准名称",
          align: "center",
          key: "list",
          width: 240,
          render: (h, params) => {
            return h(
              "div",
              {
                attrs: {
                  class: "subCol",
                },
              },
              [
                h(
                  "ul",
                  this.data1[params.index].list.map((item) => {
                    return h("li", {}, item.name);
                  })
                ),
              ]
            );
          },
        },
      ],
      data1: [
        {
          type: "基础类",
          list: [
            {
              name: "术语SZY101-2019",
            },
            {
              name: "信息分类及编码规定SZY102-",
            },
            {
              name: "标准体系SZY103-2019",
            },
          ],
        },
      ],
    };
  },
};
</script>
<style lang="less">
.subCol > ul > li {
  margin: 0 -18px;
  list-style: none;
  text-align: center;
  padding-top: 9px;
  border-bottom: 1px solid #ccc;
  overflow-x: hidden;
  min-height: 42px;
}
.subCol > ul > li:last-child {
  border-bottom: none;
}
.test {
  height: 100px;
  padding: 10px;
}
.box {
  width: 100%;
  height: 100%;
  background: coral;
}
</style>